import "core-js/modules/es.array.map.js";
import { Fragment } from 'vue-fragment';
import SkrButton from '@/components/button/SkrButton.vue';
import Heading from '@/components/Heading.vue';
import InviteHeader from '@/components/pages/invite/InviteHeader.vue';
import UploadedFilesList from '@/components/UploadedFilesList.vue';
import UppyUploader from '@/components/UppyUploader.vue';
import useUserPlanInfo from '@/composables/useUserPlanInfo';
import { useBusinessStore } from '@/stores/business';
import { useUserStore } from '@/stores/user';
export default defineComponent({
  components: {
    Heading: Heading,
    InviteHeader: InviteHeader,
    UppyUploader: UppyUploader,
    UploadedFilesList: UploadedFilesList,
    Fragment: Fragment,
    SkrButton: SkrButton
  },
  setup: function setup() {
    var businessStore = useBusinessStore();
    void businessStore.getSettings();
    var userStore = useUserStore();
    var emailVerified = computed(function () {
      return userStore.emailVerified;
    });
    var _useUserPlanInfo = useUserPlanInfo(),
      isNewFreePlan = _useUserPlanInfo.isNewFreePlan;
    return {
      emailVerified: emailVerified,
      isNewFreePlan: isNewFreePlan
    };
  },
  data: function data() {
    return {
      uploadList: [],
      maxSimultaneousUploads: 15
    };
  },
  head: function head() {
    return {
      title: 'Upload'
    };
  },
  computed: {
    ctaText: function ctaText() {
      if (this.uploadList.length === 1) {
        return this.$t('global.next');
      } else {
        return this.$t('signature_request.upload.view_uploaded_documents');
      }
    },
    uploadDone: function uploadDone() {
      return this.uploadList.length > 0;
    }
  },
  methods: {
    title: function title() {
      if (this.uploadList.length) {
        return this.$t('signature_request.upload.title_after_upload');
      } else {
        return this.$t('signature_request.upload.title');
      }
    },
    subtitle: function subtitle() {
      if (!this.emailVerified) {
        return this.$t('signature_request.upload.unverified_email_text');
      } else {
        if (this.uploadList.length === 1) {
          return this.$t('signature_request.upload.subtitle_after_upload_one');
        }
        if (this.uploadList.length > 1) {
          return this.$t('signature_request.upload.subtitle_after_upload_many');
        }
        return this.$t('signature_request.upload.subtitle');
      }
    },
    nextStep: function nextStep() {
      if (this.uploadList.length === 1) {
        void this.$router.push({
          name: 'invite-id',
          params: this.uploadList[0].id ? {
            id: this.uploadList[0].id
          } : undefined
        });
      } else {
        void this.$router.push({
          name: 'index',
          query: {
            tab: 'empty'
          }
        });
      }
    },
    mapResponseToFileList: function mapResponseToFileList(response) {
      this.uploadList = response.map(function (doc) {
        var _doc$response, _doc$response2, _doc$response3;
        return {
          id: (_doc$response = doc.response) === null || _doc$response === void 0 ? void 0 : _doc$response.body.id,
          name: (_doc$response2 = doc.response) === null || _doc$response2 === void 0 ? void 0 : _doc$response2.body.title,
          documentId: (_doc$response3 = doc.response) === null || _doc$response3 === void 0 ? void 0 : _doc$response3.body.documentId
        };
      });
    }
  }
});